// Import Angular stuff

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

@Injectable()
export class ScopeService {
  private readonly PATH = '/api/scopes';

  constructor(private httpClient: HttpClient) { }

  public search(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.PATH);
  }

  public get(key: String): Observable<any> {
    return this.httpClient.get<any>(this.PATH + '/' + key);
  }

  public post(payload: any): Observable<any[]> {
    return this.httpClient.post<any[]>(this.PATH, payload);
  }

  public put(key: String, payload: any): Observable<any> {
    return this.httpClient.put<any>(this.PATH + '/' + key, payload);
  }
}
